import React from 'react';
import '../App.css';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Cards from '../components/Cards';

function Projects() {
  return (
    <>
      <Navbar />
      <Cards />
      <Footer />
    </>
  );
}

export default Projects;